import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'
import Register from '@/components/Register'
import Login from '@/components/Login'

// lazy load:
// const Account = () => import('@/components/Account.vue')
const MyOrders = () => import('@/components/MyOrders.vue')
const MyCreatives = () => import('@/components/MyCreatives.vue')
const Requisites = () => import('@/components/Requisites.vue')
const OrderNew = () => import('@/components/OrderNew.vue')
const NotFound = () => import('@/components/NotFound.vue')

const routerHistory = createWebHistory()
const routes: RouteRecordRaw[] = [
    {
      path: '/',
      component: OrderNew
    },
    {
      path: '/order/new',
      component: OrderNew,
    },
    {
      path: '/myorders',
      component: MyOrders,
    },
    {
      path: '/mycreatives',
      component: MyCreatives,
    },
    {
      path: '/requisites',
      component: Requisites,
    },
    {
      path: '/auth/login',
      component: Login,
    },
    {
      path: '/auth/register',
      component: Register,
    },
    { path: '/404', component: NotFound },
    { path: '/:catchAll(.*)', redirect: '/404' }
]
//
const router = createRouter({
  history: routerHistory,
  routes: routes,
  linkExactActiveClass: 'active'
})

// router.beforeEach((to, from, next) => {
//  if (to.name !== 'Login' && !isAuthenticated) next({ path: '/auth/login' })
//   else next()
// })
export default router
