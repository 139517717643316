<template>

    <div class="order__new grid">
      <h1>Заказ рекламной кампании</h1>

      <OrderNewCalc
        v-show="activeStep === 0"
        @nextActive="activeStep++"
      />

      <OrderNewInvoice
        v-if="activeStep === 1"
        @nextActive="activeStep++"
        @prevActive="activeStep--"
      />
      <el-result 
      v-if="activeStep === 2"
      icon="success" 
      title="Заказ принят!" 
      subTitle="Наш менеджер свяжется с вами в ближайшее время."
      ></el-result>
    </div>
    

</template>

<script setup>
import {ref} from 'vue'
import OrderNewCalc from '@/components/OrderNewCalc.vue'
// import OrderNewCreativeSelect from '@/components/OrderNewCreativeSelect.vue'
import OrderNewInvoice from '@/components/OrderNewInvoice.vue'

const steps = [
  {id: 0, number: 'Шаг 1', title: 'Настройка РК'},
  // {id: 1, number: 'Шаг 2',  title:'Выбор ролика'},
  {id: 1, number: 'Шаг 2', title:'Оформление заказа'},
];
const activeStep = ref(0)

</script>

<style  lang="sass">
@import "@/styles/var.sass"
@import "@/styles/elements.sass"

h1
  margin-top: 0  
  width: 100%
  text-align: center
.steps
  width: 100%

.order__new
  justify-items: center
  width: 100%
  
.step__section

  max-width: 800px
  box-sizing: border-box
  padding: 30px
  // height: max-content
  


</style>
