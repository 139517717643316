<template>
  <div>
    <div class="form-wrapper grid">
      <div class="grid form-wrapper__form">
        <h2 class="form-wrapper__title">Регистрация</h2>
        <Form @submit="handleLogin" :validation-schema="schema">

          <!-- <el-input class="form-control" name="username" type="text" placeholder="Please input" v-model="input"></el-input> -->
          <div class="form-group">
            <!-- <label for="username" class="input__title">Имя пользователя</label> -->
            <Field name="username" type="text" class="form-control input__text" placeholder="Имя пользователя" />
            <ErrorMessage name="username" class="error-feedback input__text_error" />
          </div>
          <div class="form-group">
            <!-- <label for="username" class="input__title">Имя пользователя</label> -->
            <Field name="company" type="text" class="form-control input__text" placeholder="Компания" />
            <ErrorMessage name="username" class="error-feedback input__text_error" />
          </div>
          <div class="form-group">
            <!-- <label for="username" class="input__title">Имя пользователя</label> -->
            <Field name="phone" type="tel" class="form-control input__text" placeholder="Телефон"/>
            <ErrorMessage name="username" class="error-feedback input__text_error" />
          </div>
          <div class="form-group">
            <!-- <label for="username" class="input__title">Имя пользователя</label> -->
            <Field name="email" type="text" class="form-control input__text" placeholder="E-mail" />
            <ErrorMessage name="username" class="error-feedback input__text_error" />
          </div>
          <br>
          <div class="form-group">
            <!-- <label for="password" class="input__title">Пароль</label> -->
            <Field name="password" type="password" class="form-control input__text" placeholder="Пароль"/>
            <ErrorMessage name="password" class="error-feedback input__text_error" />
          </div>

          <div class="form-group">
            <!-- <label for="password" class="input__title">Пароль</label> -->
            <Field name="confirmPassword" type="password" class="form-control input__text" placeholder="Повторите пароль"/>
            <ErrorMessage name="password" class="error-feedback input__text_error" />
          </div>
          <br>
          <el-checkbox v-model="checkOferta"></el-checkbox>
          <span class="checkbox__title">Я принимаю условия <a href="">Пользовательского соглашения</a> и <a href="">Политики конфиденциальности</a></span>
          <br>
          <br>
          <div class="form-group button__group grid">
            <button class="button button__primary">Зарегистрироваться</button>
          </div>

          <div class="form-group">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
          </div>
        </Form>




        <!-- <el-input placeholder="Имя" v-model="Name"></el-input>
        <el-input placeholder="Компания" v-model="Company"></el-input>
        <el-input placeholder="E-mail" v-model="Mail"></el-input>
        <el-input placeholder="Телефон" v-model="Phone"></el-input>
        <div>
          <input type="checkbox">
          <span>  Регистрируясь на сайте вы соглашаетесь с договорм оферты</span>
        </div>
        <div>
          <input type="checkbox">
          <span>  Согласие на обработку персональных данных</span>
        </div>
        <button class="button button__primary" type="button" name="button">Зарегистрироваться</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      company: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      phone: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      confirmPassword: yup
        .string()
        .required("Please confirm your password")
        .when("password", {
          is: password => (password && password.length > 0 ? true : false),
          then: yup.string().oneOf([yup.ref("password")], "Password doesn't match")
        })
    })
    let checkOferta = ref('')
    checkOferta.value = false

    return {
      Name: ref(''),
      Company: ref(''),
      Mail: ref(''),
      Phone: ref(''),
      checkOferta

    }
  }
}
</script>

<style scoped lang="sass">
@import "@/styles/var.sass"
@import "@/styles/elements.sass"

.checkbox__title
  padding-left: 10px
  font-size: 14px
</style>
