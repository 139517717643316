export const citiesArray = [
    {
      id: 0,
      name: 'Волхов',
      population: 43969, //4500
      price: {
        'Питер FM': 360,
        'Радио Ваня': 240
      },
      stations: ['Питер FM', 'Радио Ваня']
    }, // price for 30sec
    {
      id: 1,
      name: 'Выборг',
      population: 74054, //7500
      price: {
        'Питер FM': 420,
        'Радио Ваня': 300
      },
      stations: ['Питер FM', 'Радио Ваня']
    },
    {
      id: 2,
      name: 'Кириши',
      population: 50079, //5700
      price: {
        'Питер FM': 380,
        'Радио Ваня': 260
      },
      stations: ['Питер FM', 'Радио Ваня']
    },
    {
      id: 3,
      name: 'Кингисепп',
      population: 44612, //7800
      price: {
        'Питер FM': 400,
        'Радио Ваня': 280
      },
      stations: ['Питер FM', 'Радио Ваня']
    },
    {
      id: 4,
      name: 'Луга',
      population: 33704, //4000
      price: {
        'Питер FM': 420,
        'Радио Ваня': 300
      },
      stations: ['Питер FM', 'Радио Ваня']
    },
    {
      id: 5,
      name: 'Тосно',
      population: 35099, //4000
      price: {
        'Радио Ваня': 240
      },
      stations: ['Радио Ваня']
    },
    {
      id: 6,
      name: 'Тихвин',
      population: 57327, //7300
      price: {
        'Питер FM': 300,
      },
      stations: ['Питер FM']
    },
  ]
