// import axios from 'axios'

export function getValidPromocode(promocode) { 
  return fetch( `https://lk.m10m.ru/api/v1/promocodes/validate?name=${promocode}`,).then((r) => r.json() ) 
}

export function getPromocodes() { 
  return fetch( "/api/v1/promocodes").then((r) => r.json() ) 
}

export function getPromocodesActivated() {
  return fetch( "/api/v1/used_promocodes").then((r) => r.json() ) 
}

export function createOrder(data) {
  return fetch ('https://lk.m10m.ru/api/v1/leadgen', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
}






