<template>

    <apexchart
      type="bar"
      height="355"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>

</template>

<script>
  import { ref } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'

  export default {
    name: 'OrderCalcChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      genderSet: {
        type: Function,
        default: () => ({}),
      },
    },
    setup(props) {
      const setObj = function () {
        let a = []
        let b = {
          name: 'Мужчины',
          data: props.genderSet(0)
        }
        let c = {
          name: 'Женщины',
          data: props.genderSet(1)
        }
        a.push(b)
        a.push(c)
        return a
      }

      const series = ref({})
      series.value = setObj()

      setInterval(() => { 
        if ( JSON.stringify(setObj()) != JSON.stringify(series.value) ) {
          series.value = setObj()
        }
      }, 500)

      const chartOptions = {
        chart: {
          type: 'bar',
          height: 355,
          stacked: true,
          fontFamily: 'Rubik',
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        colors: ['#008FFB', '#FF4560'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        title: {
          text: 'Охват по целевым группам:'
        },
        xaxis: {
          categories: ["12-19", "20-29", "30-39", "40-49", "50-59","60+"],
          // title: {
          //   text: 'Percent'
          // },
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + "ч."
            }
          }
        },
      }
      return {
        series,
        chartOptions
      }
    },
  }
</script>

<style lang="sass">

.apexcharts-title-text
  font-weight: 600 

</style>
