export const priceCalculate = (companyLength, quantityPerDay) => {
  if (companyLength === 1) {
    if (quantityPerDay === 4 ) return 6950
    if (quantityPerDay === 6 ) return 8250
    if (quantityPerDay === 8 ) return 9900
    if (quantityPerDay === 10 ) return 11200
  }
  if (companyLength === 2) {
    if (quantityPerDay === 4 ) return 9750
    if (quantityPerDay === 6 ) return 12500
    if (quantityPerDay === 8 ) return 13900
    if (quantityPerDay === 10 ) return 14850
  }
  if (companyLength === 3) {
    if (quantityPerDay === 4 ) return 12750
    if (quantityPerDay === 6 ) return 14600
    if (quantityPerDay === 8 ) return 16950
    if (quantityPerDay === 10 ) return 19200
  }
}