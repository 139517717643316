import { createStore } from 'vuex'
// import { auth } from "./modules/auth"
import { newOrder } from "./modules/newOrder"

export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    // auth,
    newOrder
  },
})
