<template>

  <div class="calc grid step__section">
    <div class="calc__selectors grid">
      <div class="calc__selector_container">
        <p class="calc__selector_title">Город</p>
        <el-select
          v-model="activeCity"
          placeholder="Выберите город"
          @change="
            onChangeStore('city', citiesArray[activeCity].name),
            activeStation = '', 
            promoSucsess = false, 
            priceFirst()
          "
        >
          <el-option
            v-for="city in citiesArray"
            :value="city.id"
            :key="city.id"
            :label="city.name"
          >
          </el-option>
        </el-select>
      </div>

      <div class="calc__selector_container">
        <p class="calc__selector_title">Радиостанция</p>
        <el-select
          :disabled="!cityValidation()"
          v-model="activeStation"
          placeholder="Выберите радиостанцию"
          @change="
            onChangeStore('station', activeStation),
            promoSucsess = false
          "
        >
          <el-option
            v-for="station in citiesArray[activeCity]?.stations"
            :key="station"
            :value="station"
          >
          </el-option>
        </el-select>
      </div>

      <div class="calc__selector_container">
        <p class="calc__selector_title">Дата начала РК</p>
        <el-date-picker
        @change="onChangeStore('date_start', startDate)"
          v-model="startDate"
          type="date"
          placeholder="Начло РК"

          :disabledDate="disableD"
        >
        </el-date-picker>
      </div>
      
      <div class="calc__selector_container">
        <p class="calc__selector_title">Продолжительность РК</p>
        <el-select
          v-model="activeCompanyDuration"
          placeholder="Выберите продолжительность РК"
          @change="
            onChangeStore('company_duration', activeCompanyDuration * 7),
            promoSucsess = false, 
            priceFirst()
          "
        >
          <el-option
            v-for="len in companyDurations"
            :value="len"
            :key="len"
            :label= 'len + " нед."'
          >
          </el-option>
        </el-select>
      </div>

      <div class="calc__selector_container">
        <p class="calc__selector_title">Длительность ролика</p>
        <el-select 
          v-model="durationActive" 
          placeholder="Выберите длительность"
          @change="
            onChangeStore('creative_duration', durationActive),
            promoSucsess = false
          "
        >
          <el-option
            v-for="duration in durationsArray"
            :key="duration"
            :value="duration"
            :label="duration + ' сек.'"
          >
          </el-option>
        </el-select>
      </div>

      <div class="calc__selector_container">
        <p class="calc__selector_title">Количество выходов в день</p>
        <el-select
          v-model="quantityPerDayActive"
          placeholder="Выберите количество"
          @change="
            onChangeStore('quantity', quantityPerDayActive),
            promoSucsess = false"
        >
          <el-option
            v-for="quantityPerDay in quantityPerDayArray"
            :key="quantityPerDay"
            :value="quantityPerDay"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="calc__results">
      <OrderCalcChart
        :genderSet="genderSet"
      />
      <div class="calc__results_numbers grid" v-if="selectedValidate()">
        <p>Суммарный охват: <b> {{ ohvat() }} </b> </p>
        <p>Частота контакта: <b> {{  contacts }} </b> </p>
        
        <p >Количество контактов: <b> {{ totalContacts }} </b> </p>

      </div>
      <div class="calc__price_container grid">
        <!-- {{ quantityPerDayActive }} -->
        <span v-if="promoSucsess" class="calc__price calc__price_first">
          {{priceFirst()}} Руб.
        </span>
        <span class="calc__price calc__price_last">
          {{priceLast()}} Руб.
        </span>
        <div class="calc__promo_wrap">
          <p class="calc__promo_title">Введите промокод</p>
          <el-input
            class="calc__promo_input"
            @input="() => promoModel = stringToUpperCaseRU(promoModel)"
            placeholder="Промокод"
            
            v-model="promoModel"
            clearable
          >
          </el-input>
          <el-button
            type="primary"
            plain
            class="calc__promo_button"
            @click="promocodeValidate()"
            :disabled="!selectedValidate()"
          >
            Применить
          </el-button>
        </div>
      </div>
    </div>
    <div class="calc__btn_container grid">
      <el-button 
        class="button__nextStep"
        type="primary" 
        round 
        v-on:click="$emit('nextActive')" 
        :disabled="!selectedValidate()"
      >
        Далее
      </el-button>
    </div>
  </div>
  
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import OrderCalcChart from '@/components/OrderCalcChart.vue'
import { getPromocodes, getPromocodesActivated } from '@/helpers/fetch.ts'
import { citiesArray } from '@/models/citiesArray.ts'
import { stringToUpperCaseRU } from '@/helpers/stringToUpperCaseRU.ts'
import { arrayObjectIndexOf } from '@/helpers/arrayObjectIndexOf.ts'
import { priceCalculate } from '@/models/prices.ts'
import { getValidPromocode } from '../helpers/fetch'

const quantityPerDayArray = [4, 6, 8, 10]
const durationsArray = [15, 20, 30]
const companyDurations = [1, 2, 3]
const stationsSets = {
  'Радио Ваня': {
    coverage: 24.4,
    gender: [0.458, 0.542],
    age: [9.7, 9.5, 24.7, 21, 17.5, 17.6]
  },
  'Питер FM': {
    coverage: 30.8,
    gender: [0.449, 0.551],
    age: [8.7, 9, 18.4, 16.9, 19.9, 27]
  }
}

const store = useStore()
//запись в store
const onChangeStore = (key, value) => store.commit('setOrderParameter', {key, value})

const activeCity = ref('')
const activeStation = ref('')
const startDate = ref('')
const activeCompanyDuration = ref('')
const durationActive = ref('')
const quantityPerDayActive = ref('')
const promoModel = ref('')
const promoRef = ref('')
const promoSucsess = ref(false)
const nameModel = ref('Новая кампания')
const promocodeActivated = ref('')
//запись в store дефолтового названия 
onChangeStore('name', nameModel.value)

//Все промокоды
const promocodes = ref({})

//Использльзованные промокоды
// const promocodesActivated = ref({})

// onMounted(async() => { 
//   promocodes.value = await getPromocodes()
//   promocodesActivated.value = await getPromocodesActivated()
// })

//введён ли город?
const cityValidation = () => activeCity.value.toString().length > 0

// Проверка > выбраны ли параметры?
const selectedValidate = function () {
  const cityOk = () => (`${activeCity?.value}`.length) === 0 ? 0 : 1
  const activeStationOk = () => (`${activeStation?.value}`.length) === 0 ? 0 : 1
  const quantityPerDayActiveOk = () => (quantityPerDayActive?.value.length) === 0 ? 0 : 1
  const startDateOk = () => (startDate?.value.length) === 0 ? 0 : 1
  const durationActiveOk = () => (durationActive?.value.length) === 0 ? 0 : 1
  const activeCompanyDurationOk = () => (activeCompanyDuration?.value.length) === 0 ? 0 : 1
  
  return cityOk() * activeStationOk() * quantityPerDayActiveOk() * startDateOk() * durationActiveOk() * activeCompanyDurationOk()
}

// Длительность РК в днях
// const durationInDays = () => ((startStopDate.value[1] - startStopDate.value[0]) / 1000 / 60 / 60 / 24 )
const durationInDays = () => parseInt(activeCompanyDuration.value) * 7

// Расчёт цены
const priceFirst = function() {
  const price = ref()
  if (selectedValidate() === 0) { 
    price.value = 0
  } else { 
    //price.value = Math.round( citiesArray[activeCity.value]?.price[activeStation.value] / 30 * quantityPerDayActive.value  * durationActive.value * durationInDays() )
     price.value = Math.round(priceCalculate(activeCompanyDuration.value, quantityPerDayActive.value) / 30 * parseInt(durationActive.value))
  }
  onChangeStore('price', price.value)
  return price.value
}    
    
    //Расчёт скидки
    const priceLast = function() {
      if (promoSucsess.value) {
        //Проверка типа скидки и расчёт
        let priceWithDiscount
        if (promocodeActivated.value.type === 'fixed_price'){
          priceWithDiscount = promocodeActivated.value.value
          onChangeStore('price_total', priceWithDiscount)
          return priceWithDiscount
        } else if (promocodeActivated.value.type === 'discount_amount') {
          priceWithDiscount = priceFirst() - promocodeActivated.value.value
          onChangeStore('price_total', priceWithDiscount)
          return priceWithDiscount
        } else {
          let discount = Math.round(priceFirst() * promocodeActivated.value.value / 100)
          priceWithDiscount = priceFirst() - discount
          onChangeStore('price_total', priceWithDiscount)
          return priceWithDiscount
        }
      } else {
        onChangeStore('price_total', priceFirst())
        return priceFirst()
      }
    }

    // Расчёт охвата для графика
    const ohvat = function() {
      const y = ref('')
      if (selectedValidate() == 0) { 
        y.value = 0
      }
      else { 
        y.value = Math.round(citiesArray[activeCity.value]?.population * stationsSets[activeStation.value].coverage / 100 / 3.5 * Math.log(quantityPerDayActive.value * durationInDays()))
      }
      return y.value
    } 

    const contacts = computed(() =>  Math.floor( 1 + 0.20 * (parseInt(quantityPerDayActive.value) * parseInt(activeCompanyDuration.value) * 7) ** 0.6529421)) 
    const totalContacts = computed(() => contacts.value * parseInt(ohvat()))

    // Массивы данных для графика
    const genderSet = function(n) { 
      const y = ref('')
      let m = []
      if (selectedValidate() == 0) { 
        y.value = 0
        m.push(0, 0, 0, 0, 0, 0)
      }
      else { 
        let a = ohvat() * stationsSets[activeStation.value]?.gender[n]
        for (let i of stationsSets[activeStation.value]?.age) {
          let b = Math.round(i * a / 100)
          m.push(b)
        }
      }
      return m
    }

    // возвращает можно ли выбать дату для datepicker, не ранее чем через 3 дня
    const disableD = (date) => {
      const startPoint = Date.now() + 259200000
      if (date < startPoint) {
          return true
      }
      else return false
    }
   
    const promocodeValidate = async function () {

      const currentData = store.getters.getNewOreder
      const validPromocode = await getValidPromocode(promoModel.value) || null

      const cityMess = ref('') 
      const stationMess = ref('') 
      const durationMess = ref('') 
      const creativeDurationMess = ref('') 
      const quantityPerDayMess = ref('') 

      const formDataValidation = () => {
        //Валидация города
        const promoValidateCity = validPromocode.data.cities.includes(currentData.city)
        if(!promoValidateCity) cityMess.value = (`<br>    города: ${ validPromocode.data.cities }.`)
        //валидация станции
        const promoValidateStation = validPromocode.data.stations.includes(currentData.station)
        if(!promoValidateStation) stationMess.value = (`<br>    радиостанции: ${ validPromocode.data.stations }.`)
        //валидация Период проведения рекламной кампании.
        const promoValidateDuration = currentData.company_duration <= validPromocode.data.max_company_duration
        if(!promoValidateDuration) durationMess.value = (`<br>    длительность кампании до: ${ validPromocode.data.max_company_duration } дней.`)
        //валидация длительности ролика
        const promoValidateCreativeDuration = currentData.creative_duration <= validPromocode.data.max_creative_duration
        if(!promoValidateCreativeDuration) creativeDurationMess.value = (`<br>    длительность ролика до ${ validPromocode.data.max_creative_duration } секунд.`)
        //валидация кол-во роликов в день
        const promoValidateQuantityPerDay = currentData.quantity <= validPromocode.data.max_quantity_per_day
        if(!promoValidateQuantityPerDay) quantityPerDayMess.value = (`<br>    максимальное колличество роликов в день: ${ validPromocode.data.max_quantity_per_day }.`)
        
        return  promoValidateCity && promoValidateStation && promoValidateDuration && promoValidateCreativeDuration && promoValidateQuantityPerDay
      }
      //валидация введён ли сущестувующий промокод?
      if (validPromocode.status === 200) {    
        //Вывод сообщения
        if (formDataValidation()){
          promocodeActivated.value = validPromocode.data
          promoSucsess.value = true
          onChangeStore('promocode_id', promocodeActivated.value.id)
          ElMessage.success(`Промокод ${promocodeActivated.value.name} применён`)
        } else {
          ElMessage({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `Промокод действителен только для: 
              ${cityMess.value } 
              ${stationMess.value} 
              ${durationMess.value} 
              ${creativeDurationMess.value} 
              ${quantityPerDayMess.value}`,
            type: 'warning',
            duration: 10000
          })
          promocodeActivated.value = ''
          promoSucsess.value = false
          onChangeStore('promocode_id', '')
        }  
      } else {
        ElMessage.error('Промокод не действителен')
        promocodeActivated.value = ''
        promoSucsess.value = false
        onChangeStore('promocode_id', '')
      }
    }

    
</script>

<style lang="sass">
@import "@/styles/var.sass"
@import "@/styles/elements.sass"

.calc
  box-sizing: border-box
  width: 100%
  padding: 30px 
  grid-template-columns: 50% 50%
  @media(max-width: 700px)
    grid-template-columns: 100%
    // grid-auto-rows: max-content
  background-color: white
  // grid-gap: 20px
  &__selectors
    grid-template-columns: auto
    grid-gap: 10px
    align-items: start

.calc__selector_container
  // height: 10px
  div
    width: 300px
    box-sizing: border-box
    @media(max-width: 700px)
      width: 100%

.calc__selector_title, .calc__promo_title
  margin: 0
  margin-bottom: 5px
  font-weight: 500
  color: $fc_1

.calc__price
  font-size: 24px
  font-weight: 700

  &_container
    margin-top: 20px
    // grid-column: 2/3
    grid-template-rows: auto auto auto
    justify-content: right

  &_first
    color: $fc_1
    text-decoration: line-through

.calc__promo
  &_input
    width: 150px
  &_button
    // margin-left: 10px
    margin-top: 10px !important
  &_wrap
    margin-top: 10px
    box-sizing: border-box

.calc__selector_container::v-deep(.el-date-editor )
  width: 300px 
  
//fix ??

.calc__promo_wrap::v-deep(.el-input__inner)
  text-transform: uppercase

.calc__btn_container
  margin-top: 20px
  grid-column: 1/3
  justify-content: right
  grid-template-columns: auto
  @media(max-width: 700px)
    grid-column: 1/2

.calc__results
  box-sizing: border-box

.calc__results_numbers
  padding: 10px 0 10px 20px
  p
    margin: 0

</style>