export const newOrder = {
    state: {
        newOrder: {
            // name: '',
            station: '',
            city: '',
            date_start: '',
            company_duration: '',
            creative_duration: '',
            quantity: '',
            price: '',
            price_total: '',
            promocode_id: '',
            inn: '',
            email: '',
            phone: '',
            company_name: '',
            fio: '',
        }
    },
    mutations: {
        setOrderParameter: (state, e) => {
            state.newOrder[e.key] = e.value
        }
    },
    // actions: {
    // },
    getters: {
        getNewOreder: state => {
            return state.newOrder
        }
    } 
}