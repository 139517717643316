<template>
  <div class="form-wrapper grid">
    <div class="form-wrapper__form">
      <h2 class="form-wrapper__title">Вход</h2>
      <!-- <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->

      <Form @submit="handleLogin" :validation-schema="schema">

        <!-- <el-input class="form-control" name="username" type="text" placeholder="Please input" v-model="input"></el-input> -->
        <div class="form-group">
          <!-- <label for="username" class="input__title">Имя пользователя</label> -->
          <Field name="username" type="text" class="form-control input__text" placeholder="Имя пользователя" />
          <ErrorMessage name="username" class="error-feedback input__text_error" />
        </div>
        <div class="form-group">
          <!-- <label for="password" class="input__title">Пароль</label> -->
          <Field name="password" type="password" class="form-control input__text" placeholder="Пароль"/>
          <ErrorMessage name="password" class="error-feedback input__text_error" />
        </div>

        <div class="form-group button__group grid">
          <button class="btn btn-primary btn-block button button__primary" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Войти</span>
          </button>
          <button class="button button__secondary">Зарегистрироваться</button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate"
import * as yup from "yup"
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Введите имя пользователя"),
      password: yup.string().required("Введите пароль"),
    })
    return {
      loading: false,
      message: "",
      schema,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile")
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/profile")
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    },
  },
}
</script>
<style scoped lang="sass">
@import "@/styles/var.sass"
@import "@/styles/elements.sass"

.button
  margin: 10px
  &__group
    margin: 20px
    grid-template-columns: auto auto
    justify-content: center
</style>
