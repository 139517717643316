<template>

  <div class="step__section">
    <h2 class="step__section_title">Заказ</h2>
    <div class="order__form grid">
      <span class="order__form_table_title">
        Наименование
      </span>
      <span class="order__form_table_title">
        Стоимость
      </span>
      <span>
        {{`Рекламная кампания (город ${orederSet.city}, радиостанция ${orederSet.station}, начало РК ${startDate}, длительность ${orederSet.company_duration} нед., кол-во роликов в день ${orederSet.quantity}, длительность ролика ${orederSet.creative_duration}сек.)`}}
        
      </span>
      <span class="order__form_table_price">
        {{orederSet.price_total}} 
        руб.
      </span>
    </div>

    <h2 class="step__section_subtitle">Реквизиты для выставления счёта</h2>

    <div class="invoice__form_item" v-for="requisite in requisites" :key="requisite.id">
      <div class="invoice__form_title">
        {{requisite.name}}
      </div>
      <el-input
        class="invoice__form_input br_red"
        :class="{
          'br_red': true
        }"
        :placeholder="requisite.placeholder"
        v-model="requisite.model.value"
        size="small"
        @change="requisite.validate.checkState()"        
        @input="onChangeStore(requisite.orderModel, requisite.model.value)"
      >
      </el-input>
      <div
        class="validation__title"
        style="display: none"
        :class="{
          'not-valid': !requisite.validate.getState()
        }"
      >
        *{{requisite.validate.mess.value}}
      </div>
      
    </div>
    <div class="calc__personal-check">
      <el-checkbox v-model="checkPersonal" label=" " size="large" />
      <span>Согласен с <a href="https://lk.m10reklama.ru/personal_data.pdf" target="_blank">политикой обработки персоналных данных</a></span>
    </div>
    <div class="calc__btn_container grid">
      <div></div>
      <el-button 
        class="button__nextStep crSel_btn"
        type="default" 
        round 
        v-on:click="$emit('prevActive')" 
      >
        Назад
      </el-button>
      <el-button 
        class="button__nextStep"
        type="primary" 
        round 
        :disabled="!checkPersonal"
        v-on:click="submitForm()">
        Офрмить заказ
      </el-button>
    </div>
  </div>

</template>

<script >
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { createOrder, getCompanyData, setCompanyForm } from '@/helpers/fetch.ts'

export default {
  
  name: 'OrderNewInvoice',
  emits: ['nextActive'],
  setup(props, {emit}) {
    const store = useStore()
    const onChangeStore = (key, value) => store.commit('setOrderParameter', {key, value})

    const checkPersonal = ref(true)

    const formatDate = (date) => {
      let dd = date.getDay()
      if (dd < 10) dd = '0' + dd
      let mm = date.getMonth() + 1
      if (mm < 10) mm = '0' + mm
      let yy = date.getFullYear() % 100
      if (yy < 10) yy = '0' + yy;
      return dd + '.' + mm + '.' + yy
    }
    const orederSet = computed(() => {
      return store.getters.getNewOreder
    })
    const startDate = computed(() => {
      return formatDate(orederSet.value.date_start)
    })
    const stopDate = startDate
    // const stopDate = computed(() => {
    //   return formatDate(orederSet.value.date_stop)
    // }) 
    //v-model для форм
    const companyName = ref('')
    const inn = ref('')
    const email = ref('')
    const phone = ref('')
    const fio = ref('')

    const requisites = [
      {
        id: 0,
        name: 'Название компании',
        placeholder: 'Название компании',
        model: companyName,
        orderModel: 'company_name',
        validate: { 
          state: ref(true),
          mess: ref(''),

          getState: function() {
            return this.state.value      
          },

          checkState: function() {
            this.state.value = companyName?.value.length > 0 ?  true : false 
            this.mess.value = this.state.value ? '' : 'Введите название компании'
          }
        }
      },
      {
        id: 1,
        name: 'ФИО',
        placeholder: 'Иванов Иван Иванович',
        model: fio,
        orderModel: 'fio',
        validate: { 
          state: ref(true),
          mess: ref(''),

          getState: function() {
            return this.state.value      
          },

          checkState: function() {
            this.state.value = companyName?.value.length > 0 ?  true : false 
            this.mess.value = this.state.value ? '' : 'Введите название компании'
          }
        }
      },
      {
        id: 2,
        name: 'ИНН',
        placeholder: '4712345678',
        model: inn,
        orderModel: 'inn',
        validate: { 
          state: ref(true),
          mess: ref(''),

          getState: function() {
            return this.state.value         
          },
          
          checkState: function() {
            const regexp = /^[0-9]+$/;
            const lengthValidate = () => ( inn?.value.length == 10 || inn?.value.length == 12 ) ? true : false 
            this.state.value = regexp.test(inn?.value) && lengthValidate()
            this.mess.value = this.state.value ? '' : 'Введите корректный ИНН.'
          }
          // checkState: function() {
          //   const regionValidate = () => (inn?.value[0] == 4) && (inn?.value[1] == 7) ? true : false
          //   this.mess.value = regionValidate() ? '' : 'В данный момент сервис доступен для юридических лиц из Ленинградской области'
          //   const lengthValidate = () => ( inn?.value.length == 10 || inn?.value.length == 12 ) ? true : false 
          //   this.mess.value = lengthValidate() ? this.mess.value : 'ИНН должен содержать 10 цифр для ООО или 12 для ИП'
          //   this.state.value = lengthValidate() && regionValidate()
          // }
        }
      },
      {
        id: 3,
        name: 'Email',
        placeholder: 'example@mail.com',
        model: email,
        orderModel: 'email',
        validate: { 
          state: ref(true),
          mess: ref(''),

          getState: function() {
            return this.state.value         
          },

          checkState: function() {
            const regexp = /^\S+@\S+\.\S+$/;
            this.state.value = regexp.test(email?.value)
            this.mess.value = regexp.test(email?.value) ? '' : 'Введите email'
          }
        }
      },
      {
        id: 4,
        name: 'Телефон',
        model: phone,
        placeholder: '+7 (999) 888 44 33',
        orderModel: 'phone',
        validate: { 
          state: ref(true),
          mess: ref(''),

          getState: function() {
            return this.state.value         
          },

          checkState: function() {
            const regexp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
            this.state.value = regexp.test(phone?.value)
            this.mess.value = regexp.test(phone?.value) ? '' : 'Введите телефон'

          }
        }
      },
    ]

    const submitForm = () => {
      requisites.forEach( req => req.validate.checkState() )
      if ( requisites[0].validate.getState() 
        && requisites[1].validate.getState() 
        && requisites[2].validate.getState() 
        && requisites[3].validate.getState() 
        && requisites[4].validate.getState()
      ) {
        createOrder( orederSet.value );
        emit('nextActive')
      }
    }
    
    return {
      submitForm,
      companyName,
      inn,
      requisites,
      startDate,
      orederSet,
      onChangeStore,
      checkPersonal
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/styles/var.sass"
@import "@/styles/elements.sass"

a
  color: black
  text-decoration: underline
h2
  margin-top: 0
// .step__section
//   width: 700px

.step__section_subtitle
  margin-top: 40px

.order__form
  grid-template-columns: auto minmax(150px, auto)
  grid-gap : 20px
  &_table_title
    font-weight: 600
    color: #909399

.order__form_table_price
  font-weight: 700

.calc__btn_container
  margin-top: 20px
  justify-items: left
  grid-gap: 20px
  grid-template-columns: minmax(0,1fr) 150px 150px 

.button__nextStep
  margin: 0 !important

.not-valid
  display: block !important

.calc__personal-check
  padding: 15px 5px 5px 5px
</style>
