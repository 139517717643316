import { createApp } from 'vue'
import router from '@/router'
import App from '@/App.vue'
import ElementPlus from 'element-plus'
import store from '@/store'

import '@/styles/normalize.css'
import 'element-plus/lib/theme-chalk/index.css'

// import '@/styles/index.css'

createApp(App)
  .use(router)
  .use(store)
  .use(ElementPlus)
  .mount('#app')
